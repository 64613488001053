import classNames from "classnames";
import { format } from "date-fns";
import React from "react";
import { Mail, MoreVertical, Trash } from "react-feather";
import styled from "styled-components";
import { DrawerBody, DrawerSection, DrawerTitle } from ".";
import { useAppState, useDispatch } from "../../hooks/useAppState";
import useDropdownSystem, {
  createDropdownState,
} from "../../hooks/useDropdownSystem";
import useFlash from "../../hooks/useFlash";
import useI18n from "../../hooks/useI18n";
import { useLoading } from "../../hooks/useLoading";
import * as actions from "../../store/actions";
import { Project, Projects, Share } from "../../store/types";
import theme from "../../theme";
import * as toasts from "../../toasts";

export default function ProjectShareDrawer({ id }: { id: string }) {
  const t = useI18n();
  const baseUrl = window.location.protocol + "//" + window.location.host;
  const [, load] = useLoading();
  const { shares, project } = useAppState((s) => ({
    shares: s.shares,
    project: s.project!,
    admin: s.session?.admin,
  }));
  const defaultShare = shares.find((s) => s.email === "");
  const emailShares = shares.filter((s) => s.email !== "");
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (project) load(() => dispatch(actions.shares.loadShares(project.id)));
  }, [project, load, dispatch]);

  return (
    <DrawerBody>
      <DrawerTitle
        title={t({
          en: "Share your report",
          fr: "Partagez votre rapport",
        })}
        lead={t({
          en: "Time to shine. Chose how you want to share your results.",
          fr: "Choisissez la manière de partager vos résultats.",
        })}
      />
      <DrawerSection title={t({ en: "Get Link", fr: "Obtenir un lien" })}>
        {t({
          en: (
            <p>
              Share the link so people can see this report in their browser.
              This way you don't have to email large files and reports are
              always up to date.
            </p>
          ),
          fr: (
            <p>
              Partagez le lien pour que vos clients puissent voir ce rapport
              dans leur navigateur. De cette façon, vous n'avez pas à envoyer de
              fichiers volumineux par e-mail et vos rapports sont toujours à
              jour.
            </p>
          ),
        })}
        {defaultShare && (
          <CopyLinkForm
            action={t({ en: "Copy link", fr: "Copier le lien" })}
            value={`${baseUrl}/p/${defaultShare.project_id}/${defaultShare.id}`}
          />
        )}
      </DrawerSection>
      <DrawerSection title={t({ en: "Export as PDF", fr: "Export PDF" })}>
        <GeneratePdf />
      </DrawerSection>
      <DrawerSection
        title={t({
          en: "Send report by email",
          fr: "Envoyez le rapport par email",
        })}
      >
        <ShareForm projectId={id} emailShares={emailShares} />
      </DrawerSection>
      <DrawerSection title={t({ en: "Download CSV", fr: "Export CSV" })}>
        {t({
          en: <p>Generate a CSV file with all report data.</p>,
          fr: (
            <p>
              Générez un fichier CSV avec toutes les données de votre rapport.
            </p>
          ),
        })}{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href={`${process.env.REACT_APP_API_URL}/api/projects/${id}/download.csv`}
          className="btn btn-primary btn-sm"
        >
          {t({ en: "Download CSV", fr: "Télécharger CSV" })}
        </a>
      </DrawerSection>
    </DrawerBody>
  );
}

function CopyLinkForm({ value, action }: { value: string; action: string }) {
  const t = useI18n();
  const [copied, setCopied] = useFlash(false, 2000);
  const copyToClipboard = (e: React.MouseEvent) => {
    e.preventDefault();
    const el = document.createElement("textarea");
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  };
  return (
    <div>
      <input
        className={classNames("form-control rr-t3 mb-2", {
          "text-success": copied,
          "border-success": copied,
        })}
        value={(copied ? "✔ " : "  ") + value}
        onClick={copyToClipboard}
      />
      <button
        className={classNames("btn btn-sm", {
          "btn-primary": !copied,
          "btn-success": copied,
        })}
        onClick={copyToClipboard}
      >
        {action}
      </button>
      {copied && (
        <Success>
          {t({ en: "Link copied to clipboard.", fr: "Lien copié." })}
        </Success>
      )}
    </div>
  );
}

export const downloadUrl = (project: Project): string =>
  `${process.env.REACT_APP_DATA_URL}/reports/${project.id}.pdf`;

function GeneratePdf() {
  const t = useI18n();
  const dispatch = useDispatch();
  const { project, udate } = useAppState((s) => ({
    project: s.project,
    udate: Projects.udate(s.project, s.pages),
  }));
  const [loading, load] = useLoading();
  const id = project?.id;
  const printing = Projects.isPrinting(project);
  const blank = project?.print_started == null && project?.print_ended == null;
  const pdfUpToDate = project != null && udate === project.print_ended;
  console.log({ pdfUpToDate, print_end: project?.print_ended, udate });
  React.useEffect(() => {
    if (printing && id) {
      const reloadProject = () => {
        dispatch(actions.projects.refreshPrinting(id)).then((p) => {
          if (p.print_ended) {
            toasts.printed(downloadUrl(p));
          }
        });
      };
      const f = setInterval(reloadProject, 2000);
      return () => clearInterval(f);
    }
  }, [dispatch, printing, id]);
  /** Export report to PDF. */
  const handleExport = (e: React.MouseEvent) => {
    e.preventDefault();
    console.log({ printing, loading, notProject: !project });
    if (printing) {
      return;
    }
    if (loading || !project) {
      return;
    }
    if (udate === project.print_ended) {
      window.open(downloadUrl(project));
      return;
    }
    load(dispatch(actions.projects.printProject(project.id)));
  };
  return (
    <div>
      {blank ? (
        <>
          <p>
            {t({
              en: "Generate a PDF version of your report.",
              fr: "Créez une version PDF de votre rapport.",
            })}
          </p>
          <button className="btn btn-primary btn-sm" onClick={handleExport}>
            {t({ en: "Create PDF", fr: "Créer le PDF" })}
          </button>
        </>
      ) : pdfUpToDate ? (
        <>
          <p>
            {t({ en: "Your PDF is up to date.", fr: "Votre PDF est à jour." })}
          </p>
          <button className="btn btn-primary btn-sm" onClick={handleExport}>
            {t({ en: "Download PDF", fr: "Télécharger le PDF" })}
          </button>
        </>
      ) : printing || loading ? (
        <>
          <p>
            {t({
              en: "Your PDF is being generated.",
              fr: "Votre PDF est en préparation.",
            })}
          </p>
          <button className="btn btn-primary btn-sm" disabled>
            {t({ en: "Please wait...", fr: "Merci de patienter..." })}
          </button>
        </>
      ) : (
        <>
          <p>
            {t({
              en: "Your PDF is out of date!",
              fr: "Votre PDF est n'est pas à jour !",
            })}
          </p>
          <button className="btn btn-primary btn-sm" onClick={handleExport}>
            {t({ en: "Create PDF", fr: "Actualiser le PDF" })}
          </button>
        </>
      )}
    </div>
  );
}

function ShareForm({
  projectId,
  emailShares,
}: {
  projectId: string;
  emailShares: Share[];
}) {
  const t = useI18n();
  const [email, setEmail] = React.useState("");
  const [submitting, submitLoad] = useLoading();
  const [success, setSuccess] = useFlash("", 4000);

  const dispatch = useDispatch();
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (submitting) {
      return;
    }
    submitLoad(dispatch(actions.shares.addShare(projectId, email))).then(() => {
      setSuccess(email);
      setEmail("");
    });
  };

  return (
    <>
      <p>
        {t({
          en: (
            <>
              Send an invite to access this report to the following email
              address:
            </>
          ),
          fr: <>Inviter les emails suivant à consulter votre rapport :</>,
        })}
      </p>
      <form onSubmit={onSubmit}>
        <label htmlFor="">{t({ en: "Email:", fr: "Email :" })}</label>
        <input
          type="email"
          className="form-control mb-2"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={submitting}
        />
        <button
          type="submit"
          className="btn btn-primary btn-sm"
          disabled={submitting}
        >
          {t({ en: "Send", fr: "Envoyer" })}
        </button>
        {success && (
          <Success>
            {t({
              en: (
                <>
                  Invitation sent to <strong>{success}</strong>
                </>
              ),
              fr: (
                <>
                  Invitation envoyée à <strong>{success}</strong>
                </>
              ),
            })}
          </Success>
        )}

        <SharesTable projectId={projectId} shares={emailShares} />
      </form>
    </>
  );
}

const dateFormat = (dt: Date) =>
  format(dt, "MM/dd") + " at " + format(dt, "HH:mm");

const getLastDownload = (s: Share): Date | undefined => {
  const ld = s.last_download && new Date(s.last_download);
  const lv = s.last_view && new Date(s.last_view);
  return (
    (ld && lv ? (ld.getTime() > lv.getTime() ? ld : lv) : ld || lv) || undefined
  );
};

function SharesTable({
  projectId,
  shares,
}: {
  projectId: string;
  shares: Share[];
}) {
  const t = useI18n();
  const dropdown = useShareDropdown();
  return shares.length > 0 ? (
    <>
      <p className="mt-4 mb-3">
        {t({
          en: "You shared your report with:",
          fr: "Vous avez partagé votre rapport avec :",
        })}
      </p>
      <table className="table border-less mb-0">
        <tbody className="border-0">
          {shares.map((share) => {
            const lastDownload = getLastDownload(share);
            return (
              <tr key={share.id} className="border-top">
                <td className="rr-t3 rr-dark-grey px-0">
                  <a className="rr-dark-grey" href={`mailto:${share.email}`}>
                    {share.email}
                  </a>
                  {(share.downloads | share.html_views) === 0 ? (
                    <>
                      <div className="small rr-grey">
                        {t({
                          en: "This user hasn't downloaded the report yet.",
                          fr: "Cet utilisateur n'a pas encore télécharger le rapport.",
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-muted small">
                        {t({
                          en: (
                            <>
                              This user has downloaded the report (
                              {share.downloads + share.html_views} times).
                            </>
                          ),
                          fr: (
                            <>
                              Cet utilisateur a téléchargé le rapport
                              {share.downloads + share.html_views} fois).
                            </>
                          ),
                        })}
                        {lastDownload && (
                          <div className="font-weight-light">
                            {t({
                              en: (
                                <>
                                  Last download the {dateFormat(lastDownload)}
                                </>
                              ),
                              fr: (
                                <>Dernier accès le {dateFormat(lastDownload)}</>
                              ),
                            })}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </td>
                <td
                  className="text-right text-nowrap rr-mid-grey"
                  style={{ verticalAlign: "middle" }}
                >
                  <MoreVertical
                    height="16"
                    onClick={(e) => {
                      e.preventDefault();
                      dropdown.select(share.id);
                    }}
                  />
                  {dropdown.isSelected(share.id) && (
                    <ShareDropdown share={share} />
                  )}
                  {/* <a
                    href="#-"
                    className="btn text-secondary btn-sm mr-1 mr-md-3"
                    onClick={(e) => {
                      e.preventDefault();
                      //setShareToResend(share);
                    }}
                  >
                    <span className="d-none d-md-inline-block pl-1">
                      {t({ en: "Send again", fr: "Envoyer à nouveau" })}
                    </span>
                  </a>
                  <a
                    href="#-"
                    className="btn text-danger btn-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      //setShareToDelete(share);
                    }}
                  >
                    <span className="d-none d-md-inline-block pl-1">
                      {t(appTexts.cancel)}
                    </span>
                  </a> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  ) : (
    <></>
  );
}

const useShareDropdown = createDropdownState();

function ShareDropdown({ share }: { share: Share }) {
  const dropdown = useShareDropdown();
  const ref = useDropdownSystem(dropdown.close);
  const [option, setOption_] = React.useState(null as null | "send" | "delete");
  const setOption = (k: typeof option) => (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setOption_(k);
  };
  const [loading, load] = useLoading();
  const dispatch = useDispatch();
  const submitDelete = (e: any) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    load(async () => {
      await dispatch(actions.shares.delShare(share.id));
      toasts.shareDeleted(share.email);
      dropdown.close();
    });
  };
  const submitSend = (e: any) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    load(async () => {
      await dispatch(actions.shares.resendInvitation(share.id));
      toasts.shareInvited(share.email);
      dropdown.close();
    });
  };
  return (
    <>
      <div className="dropdown-shadow"></div>
      <div
        className="dropdown-menu show"
        style={{
          zIndex: 2000,
          //   position: "absolute",
          //   backgroundColor: "red",
          padding: "12px 8px",
          minWidth: "256px",
          maxWidth: "320px",
        }}
        ref={ref}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="dropdown-item" onClick={setOption("send")}>
          <Mail width={18} className="rr-grey mr-1" /> Send invitation
        </button>
        {option === "send" && (
          <InOptionForm onSubmit={submitSend}>
            <div className="text-center text-nowrap">
              <button className="btn btn-sm btn-warning mr-2" type="submit">
                Send invitation
              </button>
            </div>
          </InOptionForm>
        )}

        <button className="dropdown-item" onClick={setOption("delete")}>
          <Trash width={18} className="text-danger mr-1" /> Delete
        </button>
        {option === "delete" && (
          <InOptionForm onSubmit={submitDelete}>
            <div className="text-center text-nowrap">
              <button className="btn btn-sm btn-danger mr-2" type="submit">
                Confirm
              </button>
            </div>
          </InOptionForm>
        )}
      </div>
    </>
  );
}

const InOptionForm = styled.form`
  display: block;
  border: 1px solid ${theme.lightGrey};
  background-color: ${theme.lighterGrey};
  padding: 8px 12px;
`;

function Success({ children }: { children: any }) {
  return (
    <div className="mt-3 mb-0 alert px-3 py-1 alert-success rr-t3 rounded">
      {children}
    </div>
  );
}
