import { format, max } from "date-fns";
import { addDays } from "date-fns/esm";
import React from "react";
import styled from "styled-components";
import { DrawerBody, DrawerFooter, DrawerSection, DrawerTitle } from ".";
import { api } from "../../api";
import { useAppDrawer } from "../../hooks/useAppDrawer";
import useConnectAsUser from "../../hooks/useConnectAsUser";
import { usePromise } from "../../hooks/usePromise";
import { Team } from "../../store/types";

const dateStr = (s: string | Date | null | undefined) =>
  s ? format(new Date(s), "yyyy-MM-dd HH:mm") : "";

export default function AdminUserDrawer({ id }: { id: string }) {
  const connect = useConnectAsUser();
  const drawers = useAppDrawer();
  const { data: user } = usePromise(() =>
    api.GetUser({ userId: id }).then((r) => r.data)
  );
  const { data: teams, set: setTeams } = usePromise(() =>
    api.GetUserTeams({ userId: id }).then((r) => r.data)
  );
  const handleConnect = (e: React.MouseEvent) => {
    e.preventDefault();
    connect(id);
    drawers.close();
  };
  const handleResetClips = (team: Team) => (e: React.MouseEvent) => {
    e.preventDefault();
    api
      .UpdateTeamClips({ teamId: team.id }, { user_id: id, clips: 0 })
      .then((res) => {
        setTeams(teams!.map((t) => (t.id === res.data.id ? res.data : t)));
      });
  };
  const handleMoreDays = (team: Team) => (e: React.MouseEvent) => {
    e.preventDefault();
    api
      .UpdateTeamPlanEnd(
        { teamId: team.id },
        {
          user_id: id,
          end: max([
            addDays(new Date(team.plan_period_end || new Date()), 7),
            addDays(new Date(), 7),
          ]).toISOString(),
        }
      )
      .then((res) => {
        setTeams(teams!.map((t) => (t.id === res.data.id ? res.data : t)));
      });
  };
  const handleFeatFeeds =
    (team: Team) => (e: React.ChangeEvent<HTMLInputElement>) => {
      api
        .UpdateTeamFeatures(
          { teamId: team.id },
          { user_id: id, feat_feeds: e.target.checked }
        )
        .then((res) => {
          setTeams(teams!.map((t) => (t.id === res.data.id ? res.data : t)));
        });
    };
  return user == null ? (
    <></>
  ) : (
    <>
      <DrawerBody>
        <DrawerTitle title="Admin user" lead="" />

        <DrawerSection title="User data">
          <div>
            <Label>ID: </Label>
            <Value>
              <small className="nowrap-ellipsis d-inline">{user?.id}</small>
            </Value>
          </div>
          <div>
            <Label>Name: </Label>
            <Value>{user?.name}</Value>
          </div>
          <div>
            <Label>Email: </Label>
            <Value>
              <a href={`mailto:${user?.email}`}>{user?.email}</a>
            </Value>
          </div>
          <div>
            <Label>Created the:</Label>
            <Value>{dateStr(user?.cdate)}</Value>
          </div>
        </DrawerSection>

        {teams &&
          teams.map((team) => (
            <DrawerSection key={team.id} title={"Team " + team.name}>
              <div>
                <Label>ID:</Label>
                <Value>
                  <small>{team.id}</small>
                </Value>
              </div>
              <div>
                <Label>Plan:</Label>
                <Value>{team.plan}</Value>
              </div>
              <div>
                <Label>Clips:</Label>
                <Value>
                  {team.clips} / {team.clips_limit}
                </Value>
              </div>
              <div>
                <Label>Plan start: </Label>
                <Value>{dateStr(team?.plan_period_start)}</Value>
              </div>
              <div>
                <Label>Plan end:</Label>
                <Value>{dateStr(team?.plan_period_end)}</Value>
              </div>
              <div>
                <button
                  className="btn btn-secondary btn-sm mr-2"
                  onClick={handleResetClips(team)}
                >
                  reset clips
                </button>
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={handleMoreDays(team)}
                >
                  end + 7days
                </button>
              </div>
              <div className="mt-3">
                <div className="rr-t2b">Features</div>
                <div className="custom-control custom-switch d-flex align-items-center">
                  <input
                    id="feat_feeds"
                    className="custom-control-input"
                    type="checkbox"
                    checked={team.feat_feeds}
                    onChange={handleFeatFeeds(team)}
                  />
                  <label
                    className="custom-control-label rr-t3 py-1"
                    htmlFor="feat_feeds"
                  >
                    rss feeds
                  </label>
                </div>
              </div>
            </DrawerSection>
          ))}
      </DrawerBody>
      <DrawerFooter className="text-center">
        <button className="btn btn-primary btn-sm" onClick={handleConnect}>
          Connect as user
        </button>
      </DrawerFooter>
    </>
  );
}

const Label = styled.label`
  width: 150px;
`;

const Value = styled.span.attrs(() => ({ className: "rr-t3" }))``;
