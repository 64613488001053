import { toast } from "react-toastify";

export const saved = () => toast.success("Saved", { autoClose: 1000 });

export const success = (
  text: string,
  params = { autoClose: 2000, position: "top-right" } as const
) => toast.success(text, params);

export const printed = (url: string) =>
  toast.success("PDF ready and up to date", {
    autoClose: 2000,
    position: "top-right",
    // onClose: () => window.open(url),
  });

export const shareInvited = (email: string) =>
  toast.success(`Invitation sent to ${email}.`, {
    autoClose: 2000,
    position: "top-right",
  });

export const shareDeleted = (email: string) =>
  toast.success(`Access to report revoked for ${email}.`, {
    autoClose: 3000,
    position: "top-right",
  });

export const urlsAdded = () =>
  toast.success(`Clips added at the bottom of your report, work in progress.`);

export const refreshRunning = () =>
  toast.success(`Clip' stats and screenshots are updating.`);

export const invitationSent = (email: string) =>
  toast.success(`Invitation sent to ${email}.`, {
    autoClose: 2000,
    position: "top-right",
  });

export const error = (err: string, autoClose = 2000) =>
  toast.error(err, {
    position: "top-right",
    autoClose,
  });

export const memberRevoke = (email: string) =>
  toast.success(`Member ${email} revoked`, { autoClose: 2000 });
