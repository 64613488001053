import * as React from "react";
import { useDispatch } from "../../../hooks/useAppState";
import * as actions from "../../../store/actions";
import { Settings, Trash } from "react-feather";
import theme from "../../../theme";
import styled from "styled-components";
import { Membership } from "../../../store/types";
import { useLoading } from "../../../hooks/useLoading";
import * as toasts from "../../../toasts";
import { texts } from "../TeamsDrawer";
import useI18n from "../../../hooks/useI18n";
import useDropdownSystem, {
  createDropdownState,
} from "../../../hooks/useDropdownSystem";

export const useMemberDropdown = createDropdownState();

/**
 * Member dropdown menu.
 */
export const MemberDropdown = ({ member }: { member: Membership }) => {
  const t = useI18n();
  const dropdown = useMemberDropdown();
  const ref = useDropdownSystem(dropdown.close);
  const dispatch = useDispatch();
  const [role, setRole] = React.useState(member.role);
  const [loading, load] = useLoading();
  const [option, setOption] = React.useState(null as null | "role" | "delete");
  const displayRoleModal = (e: any) => {
    setOption(option !== "role" ? "role" : null);
  };
  const submitRole = (e: React.FormEvent) => {
    e.preventDefault();
    if (!loading) {
      load(dispatch(actions.memberships.update(member, { role })))
        .then(() => {
          toasts.saved();
          setRole("editor");
          dropdown.close();
        })
        .catch((err) => {
          if (err.response?.status === 409) {
            toasts.error(
              t({
                en: "This email is already invited.",
                fr: "Cet email est déjà invité.",
              })
            );
          }
        });
    }
  };
  const displayDeleteModal = (e: any) => {
    setOption(option !== "delete" ? "delete" : null);
  };
  const submitDelete = (e: React.FormEvent) => {
    e.preventDefault();
    if (!loading) {
      load(dispatch(actions.memberships.remove(member.user_id)))
        .then(() => {
          toasts.memberRevoke(member.email);
          dropdown.close();
        })
        .catch((err) => {
          dropdown.close();
        });
    }
  };
  return (
    <>
      <div className="dropdown-shadow"></div>
      <div
        ref={ref}
        className="dropdown-menu dropdown-menu-right show"
        style={{ zIndex: 2000, padding: "12px 8px", minWidth: "256px" }}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="dropdown-item" onClick={displayRoleModal}>
          <Settings width={18} className="mr-1" /> Change role
        </button>
        {option === "role" && (
          <InOptionForm
            onSubmit={submitRole}
            onReset={(e) => {
              e.preventDefault();
              dropdown.close();
            }}
          >
            <label className="rr-t4 text-nowrap">Change role to</label>
            <select
              autoFocus
              className="custom-select"
              value={role}
              onChange={(e) => setRole(e.currentTarget.value as any)}
            >
              <option value="admin">{t(texts.roles.admin)}</option>
              <option value="editor">{t(texts.roles.editor)}</option>
            </select>
            <button
              className="bg-none text-primary border-0 px-0 py-0 mt-3 rr-cta"
              type="submit"
            >
              Save
            </button>
          </InOptionForm>
        )}
        <button className="dropdown-item" onClick={displayDeleteModal}>
          <Trash width={18} className="text-danger mr-1" /> Delete
        </button>
        {option === "delete" && (
          <InOptionForm
            onSubmit={submitDelete}
            onReset={(e) => {
              e.preventDefault();
              dropdown.close();
            }}
          >
            <div className="text-center text-nowrap">
              <button className="btn btn-sm btn-danger mr-2" type="submit">
                Confirm
              </button>
            </div>
          </InOptionForm>
        )}
      </div>
    </>
  );
};

const InOptionForm = styled.form`
  display: block;
  border: 1px solid ${theme.lightGrey};
  background-color: ${theme.lighterGrey};
  padding: 8px 12px;
`;
