import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useProject } from "../hooks/useProject";
import { useAppState, useDispatch } from "../hooks/useAppState";
import { FullPage } from "../components/LoggedLayout";
import { AddPagesModal } from "../components/AddPagesModal";
import * as actions from "../store/actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as toasts from "../toasts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Page,
  addVersion,
  getPreviewStats,
  getProjectTitle,
  screenshotUrl,
} from "../model";
import useI18n from "../hooks/useI18n";
import appTexts from "../texts";
import { numberF } from "../utils/numberF";
import { ProjectIcon } from "../components/ui/WorkspacesTree";
import { Link, Plus, Upload } from "react-feather";
import theme from "../theme";
import { DrawerState, useAppDrawer } from "../hooks/useAppDrawer";
import ClipCard, { Check } from "../components/ui/ClipCard";
import { PageDropdown, usePageDropdown } from "../components/ui/PageDropdown";
import NothingBlock from "../components/ui/NothingBlock";
import styled from "styled-components";
import useSocketIo from "../hooks/useSocketIo";
import classNames from "classnames";
import FirstClipModal from "../components/FirstClipModal";

/**
 * Main component: list project's clips.
 */
export function ProjectClips(
  props: RouteComponentProps<{ id: string; pageId?: string; children: any }>
) {
  useSocketIo({ projectId: props.id! });
  const t = useI18n();
  const openDrawer = useAppDrawer((s: DrawerState) => s.open);

  // local state
  const [loading] = useProject(props.id || "");
  const [addClipsModal, setAddClips] = useState(false);

  // global state
  const project = useAppState((s) => s.project);
  const pages = useAppState((s) => s.pages);
  const selectState = usePageDropdown();
  useEffect(() => {
    selectState.setPageIds(pages.map((p) => p.id));
  }, [pages]);
  const dispatch = useDispatch();

  // display upload pdf block
  const canUpload = true;
  /*useAppState(
    (s) =>
      locale === "fr" ||
      (s.session?.admin && s.user?.email === "lbedubourg@gmail.com") ||
      false
  );*/

  const handleEditClip = (id: string | null) => {
    if (id) openDrawer({ clip_edit: id });
  };

  // add clips action
  const handleAddClips = (urls: string[]) => {
    dispatch(actions.pages.addPages(project!.id, urls))
      .then(() => {
        toasts.urlsAdded();
      })
      .catch((err) => {
        toasts.error(err.toString());
      });
  };

  // Reorganize pages.
  const handleMovePage = async (source: number, destination?: number) => {
    await dispatch(actions.pages.movePage(source, destination));
    toasts.saved();
  };

  // Create a new blank page
  const handleAddBlankPage = async () => {
    const page = await dispatch(
      actions.pages.addBlankPage(project!.id, pages.length)
    );
    handleEditClip(page.id);
  };

  // Sort clips
  const handleSortClips = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    await dispatch(actions.pages.sortPages(e.target.value as any));
  };

  return (
    <>
      {loading || project == null ? (
        <p>{t(appTexts.loading)}</p>
      ) : (
        <FullPage>
          {loading || !project ? (
            <></>
          ) : (
            <>
              <FirstClipModal />
              <AddPagesModal
                visible={addClipsModal}
                hide={() => setAddClips(false)}
                addPages={handleAddClips}
              />
              <div className="m-md-2 mt-md-4 pt-3 p-md-4">
                <div
                  className="d-flex align-items-center rr-h2 rr-dark-grey pb-4 border-bottom"
                  onClick={() =>
                    openDrawer({ project_design: project.id, focus: "title" })
                  }
                >
                  <ProjectIcon
                    src={project.logo || ""}
                    size={24}
                    className="mr-3"
                  />
                  <span>{getProjectTitle(project, t)}</span>
                </div>

                {/* Add clips panels */}
                <div>
                  <div className="rr-t2b rr-dark-grey mb-2 mt-4">
                    {t({ en: "Add your clips", fr: "Ajouter des captures" })}
                  </div>
                  <div className="d-flex flex-column flex-lg-row">
                    <div
                      className="rr-bg-soft-blue p-4 d-flex flex-column justify-content-between"
                      style={{ flexGrow: canUpload ? 1 : 2, flexBasis: 1 }}
                    >
                      <ul className="pl-4 mb-3 rr-t2 rr-mid-grey">
                        {t({
                          en: (
                            <>
                              <li>
                                Paste links to the coverage you want to include
                                in your report.{" "}
                              </li>
                              <li>
                                ReachReport will clip the articles and
                                automatically add relevant data.{" "}
                              </li>
                            </>
                          ),
                          fr: (
                            <>
                              <li>
                                Copiez les liens que vous souhaitez inclure dans
                                votre rapport.
                              </li>
                              <li>
                                ReachReport créera des coupures pour chaque
                                article et analysera les retombées.
                              </li>
                            </>
                          ),
                        })}
                      </ul>
                      <div>
                        <button
                          className="btn btn-primary rr-cta"
                          onClick={(e) => {
                            e.preventDefault();
                            setAddClips(true);
                          }}
                        >
                          <Link height={12} />{" "}
                          {t({
                            en: "Add clips by URL",
                            fr: "Ajouter des liens",
                          })}
                        </button>
                      </div>
                    </div>
                    {canUpload && (
                      <>
                        <VSeparator />
                        <UploadPdfSection />
                      </>
                    )}
                    <VSeparator />
                    <div
                      className="rr-bg-softer-grey p-4 d-flex flex-column justify-content-between"
                      style={{ flexGrow: 1, flexBasis: 1 }}
                    >
                      <p className="rr-t2 rr-mid-grey">
                        {t({
                          en: "Add a single article that has appeared in print.",
                          fr: "Ajouter un article presse papier ou média traditionnel.",
                        })}
                      </p>
                      <div>
                        <button
                          className="btn btn-secondary"
                          onClick={(e) => {
                            e.preventDefault();
                            handleAddBlankPage();
                          }}
                        >
                          <Plus height={16} />{" "}
                          {t({
                            en: "Add print coverage",
                            fr: "Ajouter article",
                          })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Clips listing */}
                <div className="mt-5 mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    {/* Select checkbox on the left */}
                    <div className="d-flex align-items-center">
                      {/* Select all checkbox */}
                      <div>
                        <Check
                          onClick={selectState.toggleAll}
                          checked={selectState.all}
                        />
                        {selectState.id === "all" && <PageDropdown />}
                      </div>
                      {/* resume label */}
                      <span
                        className="ml-2 rr-t2b cursor-pointer"
                        style={{ marginTop: "2px" }}
                        onClick={selectState.toggleAll}
                      >
                        {t({ en: "Clips added", fr: "Coupures ajoutées" })} (
                        {pages.length})
                      </span>
                    </div>
                    {/* Sort component on the right */}
                    <div>
                      <select
                        className="form-control"
                        onChange={handleSortClips}
                      >
                        <option>
                          {t({
                            en: "Sort clips by",
                            fr: "Trier par",
                            es: "Ordenar por",
                          })}
                        </option>
                        <option value="readers-">
                          {t({
                            en: "Most Readers First",
                            fr: "Plus Fortes Audiences en premier",
                          })}
                        </option>
                        <option value="readers+">
                          {t({
                            en: "Least Readers First",
                            fr: "Plus Faibles Audiences en premier",
                          })}
                        </option>
                        <option value="views-">
                          {t({
                            en: "Most Views First",
                            fr: "Plus Fortes Vues en premier",
                          })}
                        </option>
                        <option value="views+">
                          {t({
                            en: "Least Views First",
                            fr: "Plus Faibles Vues en premier",
                          })}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div>
                  {pages.length === 0 && (
                    <NothingBlock className="py-5">
                      🫙&nbsp;&nbsp;
                      {t({
                        en: "No clips added yet",
                        fr: "Pas encore de coupures",
                      })}
                    </NothingBlock>
                  )}
                  <DragDropContext
                    onDragStart={() => {}}
                    onDragEnd={(result) => {
                      handleMovePage(
                        result.source.index,
                        result.destination && result.destination.index
                      );
                    }}
                  >
                    <Droppable droppableId="pages">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{ paddingBottom: "200px" }}
                        >
                          {pages.map((page, i) => (
                            <div key={page.id + "-" + i}>
                              <Draggable draggableId={page.id} index={i}>
                                {(provided) => (
                                  <ClipCard
                                    key={page.id}
                                    id={page.id}
                                    kind={page.kind}
                                    draggable={provided}
                                    date={
                                      page.published_time
                                        ? new Date(page.published_time)
                                        : undefined
                                    }
                                    disabled={page.hidden}
                                    highlight={page.design_highlight}
                                    mosaic={page.design_mosaic}
                                    onepage={page.design_onepage}
                                    publicationName={page.publication_name}
                                    title={page.title}
                                    // (hidden) disabled={true}
                                    error={page.error?.code}
                                    image={addVersion(
                                      page,
                                      screenshotUrl(
                                        page.id,
                                        page.screenshot || ""
                                      )
                                    )}
                                    {...getPreviewStats(page)}
                                    open={() => {
                                      openDrawer({ clip_edit: page.id });
                                    }}
                                    loading={!page.crawled}
                                  />
                                )}
                              </Draggable>
                            </div>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </>
          )}
        </FullPage>
      )}
    </>
  );
}

export function PageMiniStats({ page }: { page: Page }) {
  const s = getPreviewStats(page);
  return (
    <StatsDiv className={"d-flex mt-1 mt-md-0"}>
      <StatDiv title="Views" className="mr-1">
        <FontAwesomeIcon icon="eye" />
        <div>{s.views ? numberF(s.views, " ") : "NA"}</div>
      </StatDiv>
      <StatDiv title="Readership" className="mr-1">
        <FontAwesomeIcon icon="users" />
        <div>{s.users ? numberF(s.users, " ") : "NA"}</div>
      </StatDiv>
    </StatsDiv>
  );
}

const StatsDiv = styled.div`
  background-color: #eeeeee;
  margin-top: 15px !important;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 0px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  justify-content: center;
  @media (min-width: 768px) {
    margin: 0px !important;
    background-color: transparent;
  }
`;

const StatDiv = styled.div`
  color: ${theme.primary} !important;
  text-align: left;
  padding: 4px 15px;
  min-width: 90px;
  border-radius: 15px;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 8px;
  div {
    display: inline-block;
    padding-left: 8px;
  }
  @media (min-width: 768px) {
    background-color: #eeeeee;
    min-width: 80px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    padding: 0px;
    padding-top: 1.25em;
    text-align: center;
    margin-bottom: 0px;
    div {
      display: block;
      padding-left: 0px;
    }
  }
`;

export function PageError({ error }: { error: Page["error"] }): JSX.Element {
  const t = useI18n();
  switch (error?.code) {
    case "BAD_URL":
      return t({
        en: <>The provided URL is not reachable</>,
        fr: <>Le lien n'est pas accessible</>,
      });
    case "SHAREDCOUNT_FAILED":
      return t({
        en: <>Access to underlying API failed (code 1)</>,
        fr: <>Erreur d'accès API interne (code 1) </>,
      });
    case "SITETRAFFIC_FAILED":
      return t({
        en: <>Access to underlying API failed (code 2)</>,
        fr: <>Erreur d'accès API interne (code 2) </>,
      });
    case "ERROR":
      return t({
        en: <>We will investigate the internal error.</>,
        fr: <>Nous avons enregistré l'erreur et allons la corriger.</>,
      });
    default:
      return error && error.code ? <>Error code: {error.code}</> : <></>;
  }
}

function VSeparator() {
  const t = useI18n();
  return (
    <div className="my-3 my-lg-0 px-3 d-flex flex-row flex-lg-column justify-content-center align-items-center">
      <VSeparatorLineTop />
      <div className="rr-t2 px-3 px-lg-0 rr-mid-grey">
        {t({ en: "Or", fr: "Ou" })}
      </div>
      <VSeparatorLineBottom />
    </div>
  );
}

const VSeparatorLineTop = styled.div.attrs(() => ({
  className: "flex-grow-1 mt-lg-3 mb-lg-2",
}))`
  background-color: ${theme.lightGrey};
  height: 2px;
  @media (min-width: 992px) {
    height: auto;
    width: 2px;
  }
`;

const VSeparatorLineBottom = styled.div.attrs(() => ({
  className: "flex-grow-1 mb-lg-3 mt-lg-2",
}))`
  background-color: ${theme.lightGrey};
  height: 2px;
  @media (min-width: 992px) {
    height: auto;
    width: 2px;
  }
`;

function UploadPdfSection() {
  const t = useI18n();
  const dispatch = useDispatch();
  const projectId = useAppState((s) => s.project?.id!);
  const [drag, setDrag] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const onFile = async (files: FileList) => {
    if (uploading) {
      return;
    }
    try {
      setUploading(true);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type !== "application/pdf") {
          continue;
        }
        await dispatch(actions.files.createFile(file))
          .then(async (res) => {
            // TOOD: request parsing of PDF file into a Page.
            console.log(res);
            await dispatch(actions.pages.importPage(projectId, res));
            toasts.success(
              "File uploaded, import process started for " + file.name
            );
          })
          .catch((err) => {
            console.log(err);
            toasts.error("Upload failed for file " + file.name);
          });
      }
    } finally {
      setUploading(false);
    }
  };
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) onFile(e.target.files);
  };
  const dragOn = (e: React.DragEvent) => {
    e.preventDefault();
    setDrag(true);
  };
  const dragOff = (e: React.DragEvent) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDrop = (e: React.DragEvent) => {
    dragOff(e);
    onFile(e.dataTransfer?.files);
  };
  return (
    <>
      <input
        type="file"
        className="custom-file-input d-none"
        id="uploadPdfInput"
        disabled={uploading}
        onChange={onFileChange}
        accept=".pdf,application/pdf"
        multiple
      />
      <DropBlock
        onDragEnter={dragOn}
        onDragOver={dragOn}
        onDragLeave={dragOff}
        onDrop={onDrop}
        dragging={drag}
      >
        <label
          className={classNames(
            "h-100 d-flex flex-column justify-content-between",
            {
              active: drag,
            }
          )}
          htmlFor="uploadPdfInput"
          onClick={(e) => {
            if (uploading) {
              e.preventDefault();
            }
          }}
          style={{
            flexGrow: 1,
            flexBasis: 1,
          }}
        >
          <p className="rr-t2 rr-mid-grey">
            {t({
              en: (
                <>
                  Import a PDF file from your monitoring tool. Drag & Drop file
                  here or click to select.
                </>
              ),
              fr: (
                <>
                  Importer un fichier issu d'un outil de veille. Glissez-déposez
                  vos fichiers ici.
                </>
              ),
            })}
          </p>
          <div>
            <div className="btn btn-secondary">
              {uploading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></span>{" "}
                  {t({
                    en: "Uploading, please wait...",
                    fr: "Envoi en cours, veuillez patienter...",
                  })}
                </>
              ) : (
                <>
                  <Upload />
                  {t({ en: "Import files", fr: "Importer des fichiers" })}
                </>
              )}
            </div>
          </div>
        </label>
      </DropBlock>
    </>
  );
}

const DropBlock = styled.div.attrs<{ dragging?: boolean }>((props) => ({
  className: "rr-bg-softer-grey p-4 " + (props.dragging ? "active" : ""),
}))<{ dragging?: boolean }>`
  flex-grow: 1;
  flex-basis: 0;
  label.active {
    background-color: ${theme.lightGrey};
  }
  &.active {
    background-color: ${theme.lightGrey};
  }
`;
